.optionUl {
    padding-inline-start: 20px;
    margin-left: 0px;
    margin: 0;
    margin-block-start: 0em;
    margin-top: 0 !important;
}
.optionSubUl {
    padding-inline-start: 20px;
}

.optionSubUl, .optionUl {
    margin-top: 0 !important;
}

.optionSub {
    display: inline-block;
    padding-right: 1em;
}
.ul-indent {
    padding-inline-start: 20px;
    margin-left: 0px;
}

.optionSub:before {
    content:'\ffed'; margin-right:0.3em;
} 

.rule-text {
    display: inline;
}

.rule-text p {
    margin-bottom: 0;
    margin-top: 0;
}

.rule-text ul {
    padding-inline-start: 30px;
}